import React from "react";

import "../css/home.css"

import "react-image-gallery/styles/css/image-gallery.css";


import image1 from "../images/gallery photos/1.jpg"
import image2 from "../images/gallery photos/2.jpg"
import image3 from "../images/gallery photos/3.jpg"
import image4 from "../images/gallery photos/4.jpg"
import image5 from "../images/gallery photos/5.jpg"
import image6 from "../images/gallery photos/6.jpg"
import image7 from "../images/gallery photos/7.jpg"
import image8 from "../images/gallery photos/8.jpg"


const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8
  ];


const Gallery3D = () => {
    return (
        <div className="gallery-container-3d animate-on-scroll">
            <div className="gallery-3d ">
                {images.map((img, index) => (
                    <div key={index} className="gallery-image-3d">
                        <img src={img} alt={`Gallery ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery3D