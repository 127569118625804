import React from "react";
import ImageGallery from "react-image-gallery";

import "../css/home.css"

import "react-image-gallery/styles/css/image-gallery.css";


import image1 from "../images/roar_gallery/roar1.JPG"
import image2 from "../images/roar_gallery/roar2.JPG"
import image3 from "../images/roar_gallery/roar3.JPG"
import image4 from "../images/roar_gallery/roar4.JPG"
import image5 from "../images/roar_gallery/roar5.JPG"
import image6 from "../images/roar_gallery/roar6.JPG"
import image7 from "../images/roar_gallery/roar7.JPG"
import image8 from "../images/roar_gallery/roar8.JPG"
import image9 from "../images/roar_gallery/roar9.JPG"
import image10 from "../images/roar_gallery/roar10.JPG"



const images = [
    {
        original: image1
    },
    {
        original: image2
    },
    {
        original: image3
    },
    {
        original: image4
    },
    {
        original: image5
    },
    {
        original: image6
    },
    {
        original: image7
    },
    {
        original: image8
    },
    {
        original: image9
    },
    {
        original: image10
    },

]


const Gallery = () => {
    return (
        <div className="gallery-div">
            <div className="gallery-container animate-on-scroll">
                <ImageGallery
                    items={images}
                    showThumbnails={false}
                    showBullets={true}
                    autoPlay={true}
                />
            </div>
        </div>
    );
}

export default Gallery