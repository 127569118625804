// src/App.js
import React, {useEffect} from 'react';
import ARCHP from "../images/elements/ARC-Backdrop-2.jpg";
import { Grid } from "@mui/material";

import Gallery from '../components/gallery';

import '../css/arc.css'



const intro_paragraph = "The QUT Robotics Club’s Remote Off-world Autonomous Rover (ROAR) program is focused on developing advanced rover systems designed to function in off-world environments, such as Mars. Perseus, the latest iteration of this initiative, builds on the foundation laid by its predecessor, Artemis, which competed in 2023. Perseus features a multi-layered system equipped with an attachable digger, infrared (IR) reader, automatic navigation, and a deployable marsupial droid designed for additional tasks. In 2024, Perseus competed in the prestigious Australian Rover Challenge, where it secured fourth place overall. The team meets every Tuesday for project nights, working collaboratively to refine their rover and other club projects."

const WaveLine = () => {
    return (
      <svg className="bottom_curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" height="10">
        <path fill="rgb(15, 22, 39)" fillOpacity="1" 
        d="M0,160L60,176C120,192,240,224,360,213.3
        C480,203,600,149,720,133.3C840,117,960,139,1080,170.7
        C1200,203,1320,245,1380,266.7L1440,288L1440,0L1380,0
        C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0
        C240,0,120,0,60,0L0,0Z"></path>
      </svg>
    );
  };

const Roar_Lead = {
    imagePath: 'aidan.jpg',
    position: 'Roar Technical Lead',
    name: "Aidan Lipinksi"
};

 

function ARC() {

    useEffect(() => {
        const handleScroll = () => {
          const elements = document.querySelectorAll(".animate-on-scroll");
          elements.forEach((element) => {
            const elementTop = element.getBoundingClientRect().top;
            if (elementTop < window.innerHeight - 200) {
              element.classList.add("visible");
            }
          });
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

  return (
    <div>
      <div class="roar_image-container">
          <img src={ARCHP} alt="Robotics" height="100%" width="100%" />
          <div class="roar_image-overlay"></div>
          <div class="roar_overlay-text">
            <h1>R.O.A.R</h1>
            <p>Remote Offworld Autonomous Rover</p>
          </div>
      </div>

      <div class="roar_blurbed-text-container">
          <Grid container spacing={3}>
              <Grid item xs={12}  md={9}>
                  <div className='animate-on-scroll roar_blurbed-text'>
                      <p>{intro_paragraph}</p>    
                  </div>
              </Grid>
              <Grid item xs={12}  md={3}> 
                <div className='roar_lead animate-on-scroll'>
                  <div className='headshot' id={Roar_Lead.firestoreId}>
                      <div className='headshot-image-border'>
                          <img className='headshot-image'
                              src={require(`../images/exec_photos/${Roar_Lead.imagePath ? Roar_Lead.imagePath : "Robot.png"}`).default}
                              alt={Roar_Lead.name} />
                      </div>

                      <div className='name-badge roar_name_badge'>
                          <h2>{Roar_Lead.name}</h2>
                          <h3>{Roar_Lead?.position}</h3>
                      </div>
                  </div>
                </div>
              </Grid>
          </Grid>
      </div>

      <WaveLine />

      <div className='roar_gallery'>
        <h1>Australian Rover Challenge 2024</h1>
        <Gallery />
      </div>
    </div>
  );
}

export default ARC;
