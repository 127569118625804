// src/App.js
import React, {useEffect} from 'react';
import Tabs from '../components/tab.js';
import { Grid } from "@mui/material";

import robot101_cover from "../images/Robots-101/Robots101-cover.jpg"
import robot101_1 from "../images/Robots-101/Robots101-1.jpg"
import robot101_2 from "../images/Robots-101/Robots101-2.jpg"
import robot101_3 from "../images/Robots-101/Robots101-3.jpg"
import robot101_4 from "../images/Robots-101/Robots101-4.jpg"
import robot101_5 from "../images/Robots-101/Robots101-5.jpg"

import qut_map from "../images/qut-map.jpg";

import QUTRC_Logo from "../images/qutrc_logo.png";
import Discord_Logo from "../images/external logos/Discord-logo.png"

import '../css/Robots101.css'


const WaveLine = () => {
    return (
      <svg className="bottom_curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" height="10">
        <path fill="rgb(15, 22, 39)" fillOpacity="1" 
        d="M0,160L60,176C120,192,240,224,360,213.3
        C480,203,600,149,720,133.3C840,117,960,139,1080,170.7
        C1200,203,1320,245,1380,266.7L1440,288L1440,0L1380,0
        C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0
        C240,0,120,0,60,0L0,0Z"></path>
      </svg>
    );
  };

const Proj_Manager = {
    imagePath: 'jasper.jpg',
    position: 'Project Manager',
    name: "Jasper Fox"
};

const Assist_Proj_Manager = {
    imagePath: 'atticus.jpg',
    position: 'Assistant Project Manager',
    name: "Atticus D'Mello"
};

const Quote_person = {
  imagePath: 'atticus.jpg',
  position: 'Assistant Project Manager 2024',
  name: "Atticus D'Mello"
};


const content = [
    {c1 : "3D Modelling and Printing", c2 : "PCB Theory and Design using KiCAD", c3 : "Robot Assembly", c4: "Introduction To Linux"},
    {c1 : "Introduction to Programming / Python", c2: "Computer Vision", c3 : "PWM / Motor Control", c4 : "System Automation"}
  ];

const ProjectTeamPhotos = () => {
    return(
<div className="grid_project_team">
  <div className="grid_project_team_item">
    <div className="headshot-image-border headshot_r">
      <img
        className="headshot-image"
        src={require(`../images/exec_photos/${Proj_Manager.imagePath ? Proj_Manager.imagePath : "Robot.png"}`).default}
        alt={Proj_Manager.name}
      />
    </div>
  </div>
  <div className="grid_project_team_item">
    <div className="name-badge_r">
      <h2>{Proj_Manager.name}</h2>
      <h3>{Proj_Manager?.position}</h3>
    </div>
  </div>
  <div className="grid_project_team_item">
    <div className="headshot-image-border headshot_r">
      <img
        className="headshot-image"
        src={require(`../images/exec_photos/${Assist_Proj_Manager.imagePath ? Assist_Proj_Manager.imagePath : "Robot.png"}`).default}
        alt={Assist_Proj_Manager.name} 
      />
    </div>
  </div>
  <div className="grid_project_team_item">
    <div className="name-badge_r">
      <h2>{Assist_Proj_Manager.name}</h2>
      <h3>{Assist_Proj_Manager?.position}</h3>
    </div>
  </div>
</div>
    
);};

function Robots101() {

    useEffect(() => {
        const handleScroll = () => {
          const elements = document.querySelectorAll(".animate-on-scroll");
          elements.forEach((element) => {
            const elementTop = element.getBoundingClientRect().top;
            if (elementTop < window.innerHeight - 200) {
              element.classList.add("visible");
            }
          });
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

  return (
    <div>
        <Grid container spacing={2} >
            <Grid item xs={12}  md={6} >
                <img src={robot101_cover} alt="Robotics" height='100%' width = "100%" />
            </Grid>
            <Grid item xs={12}  md={6} className='top-section'>
                <h1><i>The First Step in Robotics</i></h1>
                <p> Robotoics 101 is our introductory program that covers all the basics of robotics and engineering. Wether you are a first year student trying too get ahead or an experienced engineer wanting to learn new skills there is somnething for everyone</p>
            </Grid>
        </Grid>
        <div className='information'>
            <h1 className='animate-on-scroll'>The Details</h1>
            <div className="information_tabs animate-on-scroll">
                <Tabs className="tabs">
                    <div className="tab" label="Who">
                        <h2>Who can do Robotics 101?</h2>

                        <Grid container spacing={3}>
                            <Grid item xs={12}  md={6}>
                                <div className='who_text'>
                                    <p>Robotics 101 is for any student who has an intrest in Robotics. You dont need to be an experienced mechatroonics engineer or even an Engineering student, all you need is a passion for robotics and a desire to learn new skills</p>    
                                    <p>Classes are lead by our clubs Project team. Our Project Manager, {Proj_Manager.name}, and Assistant Project Manager, {Assist_Proj_Manager.name}, are both skilled mechatronics students with a solid foundation in Robotics. Moreover they are also students who understand the various hurdles a beginner may face.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12}  md={6}> 
                                <ProjectTeamPhotos />   
                            </Grid>
                        </Grid>
                    </div>

                    <div className="tab" label="What">
                        <h2>What will you learn?</h2>
                        <p>The goal of the Robotics 101 is to take you from zero experience to designing and building your very own Robot. These topics include</p>
                        <table className='simple-table'>
                        <tbody>
                            {content.map((item) => (
                            <tr key={item.id}>
                                <td>{item.c1}</td>
                                <td>{item.c2}</td>
                                <td>{item.c3}</td>
                                <td>{item.c4}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                        <p>By the end of the semester you will have a fully functioning Robot that can autonomasly track and follow a coloured cube. You will also have your own 3D printed minature license plate that you will get to design yourself</p>

                    </div>

                    <div className="tab" label="When">
                        <h2>When do we teach Robotics 101?</h2>
                        <Grid container spacing={3}>
                            <Grid item xs={12}  md={6}>
                                <div className='who_text'>
                                    <p>We hold Robotics 101 every <b>Tuesday at 6pm </b>. Roobotics 101 is run during the club project night so even if you feel confident with a topic there is still plenty to do.<n /></p>
                                    <p>If you are unavailable during that time or can't make it one week, dont stress. All content is available publically via our Robotics 101 Wiki page</p>
                                </div>
                            </Grid>
                            <Grid item xs={12}  md={6}>
                                <img src={robot101_1} alt="Robotics" height='100%' width = "100%" />
                            </Grid>
                        </Grid>
                    </div>

                    <div className="tab" label="Where">
                        <h2>Where is this class held?</h2>
                        <p>Robotics 101 is held in <b>s901 Garden Point Campus</b>.</p>
                        <img src={qut_map} alt={"QUT MAP"} width={'60%'} height={'60%'} />
                    </div>

                    <div className="tab" label="Why">
                      <h2>Why should you try Robotics 101??</h2>
                      <p>Robotics 101 is for students of all degrees and levels of experience. This program is for students by students. While University classes have required deadlines and strong expectatioons, Robotics 101 is more relaxed, fun gateway to learn new skills and make new friends.</p>

                      <div class="robots101_blurbed-text-container">
                        <Grid container spacing={3}>
                            <Grid item xs={12}  md={9}>
                                <div className='robots101_blurbed-text'>
                                  <p>"Joining the Robotics 101 program completely changed how I see engineering. I went from knowing almost nothing about robotics to building and programming my own projects in just a few weeks. The hands-on challenges were so much fun, and the mentors made everything feel achievable. Now, I’m seriously considering a career in robotics—it’s incredible to see what’s possible when you dive in!"</p>
                                </div>
                            </Grid>
                            <Grid item xs={12}  md={3}> 
                              <div className='robots101_quote'>
                                <div className='headshot' id={Quote_person.firestoreId}>
                                    <div className='headshot-image-border'>
                                        <img className='headshot-image'
                                            src={require(`../images/exec_photos/${Quote_person.imagePath ? Quote_person.imagePath : "Robot.png"}`).default}
                                            alt={Quote_person.name} />
                                    </div>

                                    <div className='name-badge'>
                                        <h2>{Quote_person.name}</h2>
                                    </div>
                                </div>
                              </div>
                            </Grid>
                        </Grid>
                    </div>

                    </div>
                </Tabs>
            </div>  
        </div>
        <WaveLine />
        <div className='animate-on-scroll'></div>

        <h1>Gallery</h1>
        <div className="gallery-container_r">
            <img src={robot101_2} alt="Robotics" className='gallery_images_r' />
            <img src={robot101_3} alt="Robotics" className='gallery_images_r gallery_images_r_alt' />
            <img src={robot101_4} alt="Robotics" className='gallery_images_r' />
            <img src={robot101_5} alt="Robotics" className='gallery_images_r gallery_images_r_alt' />
          </div>

            <h1>Resources</h1>
            <p>All Robotics 101 content is stored on a publically available wiki page. You can follow along each topic and access the relevant files. If you have any further questions you can always access out robotics club discord and join the Robotics 101 group chat to discuss progress and get online assistance. Simply join the server and select Robots 101 as a role.</p>

            <div className='bottom_section'>
                <div id="footer-links">
                    <a className="resources_link" href="https://www.notion.so/qutrc/Robots101-Wiki-1b1459112e0a42f2ab0e6879863b7752">
                        <img src={QUTRC_Logo} alt='Robotics' width={'20%'}/> 
                        <h1>Robotics101 Wiki</h1>
                    </a>
                </div>
            
            <div id="footer-links">
                <a className="resources_link" href="https://discord.gg/gaqTx5Dx">
                    <img src={Discord_Logo} alt='Robotics' width={'20%'} /> 
                    <h1>QUTRC Discord</h1>
                </a>      
            </div>
        </div>
    </div>
  );
}

export default Robots101;
