import React, { useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import Values from "../images/elements/values.svg";
import ARCHP from "../images/elements/ARC-HP.jpg";
import Sponsors from "../components/sponsors";
import Gallery3D from "../components/gallery3d";
import image1 from "../images/gallery photos/top-1.jpg";
import image2 from "../images/gallery photos/top-2.jpg";
import "../css/home.css";
import { Grid } from "@mui/material";

// Constants
const SCROLL_OFFSET = 200;

// Variables for text
const paragraph1 = "The QUT Robotics Club is a student-led organization dedicated to promoting and supporting robotics education within the QUT community. Founded on the values of collaboration and innovation, the club provides a welcoming environment where students from all disciplines and skill levels can explore the fascinating world of robotics."

const paragraph2 = "With a focus on hands-on learning and problem-solving, members work together on creative engineering and design projects, bringing ideas to life. The club's inclusive atmosphere fosters friendships and connections that go beyond the university, creating a strong network of individuals with a shared passion for robotics."

const paragraph3 = "The club offers workshops, projects, and competitions to help members develop skills in robotic design, programming, and engineering. Whether you're a beginner or advanced, there's something for everyone. Members also gain practical experience by tackling real-world challenges and participating in robotics competitions."

const paragraph4 = "In addition to technical growth, the club hosts industry talks and networking events to help members connect with professionals and explore career opportunities. These experiences equip members for successful careers while fostering friendships and networks that provide support beyond graduation."

// Components
const WaveLine = () => (
  <svg className="curve" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" height="10">
    <path
      fill="rgb(15, 22, 39)"
      fillOpacity="1"
      d="M0,160L60,176C120,192,240,224,360,213.3C480,203,600,149,720,133.3C840,117,960,139,1080,170.7C1200,203,1320,245,1380,266.7L1440,288L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
    ></path>
  </svg>
);

const WaveLineAlt = () => (
  <svg className="curve2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" height="10">
    <path
      fill="rgb(15, 22, 39)"
      fillOpacity="1"
      d="M0,160 C60,176 120,192 240,176 C360,160 480,128 600,144 C720,160 840,208 960,192 C1080,176 1200,96 1320,128 C1440,160 1440,320 1440,200 L1440,320 L0,320 Z"
    ></path>
  </svg>
);

const TextOverlay = ({ text }) => (
  <div className="text-overlay">{text}</div>
);

const CircularImage = ({ src, alt }) => (
  <img src={src} alt={alt} className="circular-image animate-on-scroll" width="80%" height="80%" />
);

const Section = ({ children, className }) => (
  <Grid container spacing={2} style={{ padding: 24 }} className={className}>
    {children}
  </Grid>
);

const ImageSection = ({ src, alt }) => (
  <Grid item xs={12} md={6}>
    <CircularImage src={src} alt={alt} />
  </Grid>
);

// Main Home Component
const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".animate-on-scroll");
      elements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        if (elementTop < window.innerHeight - SCROLL_OFFSET) {
          element.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="home">
      <div className="main-image">
        <img src={ARCHP} alt="Landing" className="home-values" width="100%" height="90%" />
        <TextOverlay text="Supporting Emerging Engineers" />
      </div>

      <div className="home-main">
        <div className="home-content">
          <Section className="grid-section-1">
            <Grid item xs={12} md={6} className="dark-blue-section animate-on-scroll">
              <p>{paragraph1}</p>
              <p>{paragraph2}</p>
            </Grid>
            <ImageSection src={image1} alt="Landing" />
          </Section>

          <WaveLine />

          <Section className="grid-section-2">
            <ImageSection src={image2} alt="Landing" />
            <Grid item xs={12} md={6}>
              <div className="home-text animate-on-scroll">
                <p>{paragraph3}</p>
                <p>{paragraph4}</p>
              </div>
            </Grid>
          </Section>

          <WaveLineAlt />

          <div className="grid-section-1">
            <Gallery3D />
          </div>

          <WaveLine />

          <div className="home-image">
            <h1 className="animate-on-scroll">Our Values</h1>
            <img src={Values} alt="QUTRC Logo" className="animate-on-scroll" />
          </div>

          <Sponsors />

          <div className="bottom-page">
            <p>
              <br />
              ABN: 34 411 341 964
              <br />
              Reach out to us via our{" "}
              <a id="rules" href="/contact">contact form</a>{" "}
              or through social media.
              <br />
              Address: 920 S Block, QUT Gardens Point Campus, 2 George St, Brisbane City QLD 4000
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
