// src/Tabs.js
import React, { useState } from 'react';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div style={tabsBoxStyle}>
      <div style={tabListStyle}>
        {React.Children.map(children, (child, index) => (
          <button
            key={index}
            style={index === activeTab ? activeTabStyle : tabStyle}
            onClick={() => setActiveTab(index)}
          >
            {child.props.label}
          </button>
        ))}
      </div>
      <div style={tabContentStyle}>
        {React.Children.toArray(children)[activeTab]}
      </div>
    </div>
  );
};

const tabsBoxStyle = {
  margin: '0 auto',
  backgroundColor: 'white',
  borderRadius: '8px',
  borderColor: 'red',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const tabListStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  borderBottom: '1px solid #ccc',
};

const tabStyle = {
  flex: '1',
  padding: '10px 20px',
  cursor: 'pointer',
  border: 'none',
  backgroundColor: 'rgb(15, 22, 39)',
  borderBottom: '2px solid transparent',
  color: 'white',
  fontSize: '150%',
  fontWeight: 'bold',
  textAlign: 'center',
};

const activeTabStyle = {
  ...tabStyle,
  backgroundColor: 'white',
  color: 'black',
};

const tabContentStyle = {
  padding: '10px',
  color: 'black',
};

export default Tabs;
